export const specialCharReferenceMap = [
  { char: "`", ref: "button_`" },
  { char: "~", ref: "button_`" },
  { char: "!", ref: "button_1" },
  { char: "@", ref: "button_2" },
  { char: "#", ref: "button_3" },
  { char: "$", ref: "button_4" },
  { char: "%", ref: "button_5" },
  { char: "^", ref: "button_6" },
  { char: "&", ref: "button_7" },
  { char: "*", ref: "button_8" },
  { char: "(", ref: "button_9" },
  { char: ")", ref: "button_0" },
  { char: "-", ref: "button_-" },
  { char: "_", ref: "button_-" },
  { char: "=", ref: "button_=" },
  { char: "+", ref: "button_=" },
  { char: "[", ref: "button_{" },
  { char: "{", ref: "button_{" },
  { char: "}", ref: "button_}" },
  { char: "]", ref: "button_}" },
  { char: "\\", ref: "button_|" },
  { char: "|", ref: "button_|" },
  { char: ";", ref: "button_:" },
  { char: ":", ref: "button_:" },
  { char: "'", ref: "button_quote" },
  { char: '"', ref: "button_quote" },
  { char: ",", ref: "button_<" },
  { char: ".", ref: "button_>" },
  { char: "<", ref: "button_<" },
  { char: ">", ref: "button_>" },
  { char: "?", ref: "button_?" },
  { char: "/", ref: "button_?" },
  { char: " ", ref: "button_space" },
];
